/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(function() {
  let clearEmailVerificationFields;
  jQuery('#account_user_time_zone').val(jstz.determine().name());

  jQuery("#rendered_signup_form, #sign_up_modal form").each(function() {
    return $(this).validate({
      onkeyup: false,
      focusCleanup: true,
      rules: {
        "account[users_attributes][0][first_name]": {
          required: true
        },
        "account[users_attributes][0][last_name]": {
          required: true
        },
        "account[users_attributes][0][password]": {
          required: true,
          minlength: 6
        },
        "account[users_attributes][0][email]": {
          required: true,
          email: true,
          remote: {
            url: "/verify_email",
            data: {
              email() { return $('[name="account[users_attributes][0][email]"]').val(); }
            },
            dataFilter(data) {
              clearEmailVerificationFields();
              data = $.parseJSON(data);

              if (data.available) {
                if (data.message) {
                  jQuery('#rendered_signup_form .email-validation.warning').html(data.message).show();
                }
                return "true";
              } else {
                const getStartedEmailValidationPlaceholder = jQuery('#rendered_signup_form .email-validation.error');

                if (getStartedEmailValidationPlaceholder.length > 0) {
                  getStartedEmailValidationPlaceholder.html(data.message).show();
                  return "false";
                } else {
                  return JSON.stringify(data.message);
                }
              }
            }
          }
        },
      },
      messages: {
        "account[users_attributes][0][email]": {
          required(rules, element) {
            removeCacheOnRemoteValidation();
            clearEmailVerificationFields();
            return 'This field is required.';
          },
          email(rules, element) {
            removeCacheOnRemoteValidation();
            clearEmailVerificationFields();
            return 'Please enter a valid email address.';
          },
          remote(rules, element) {
            return '';
          }
        }
      }
    });
  });

  var removeCacheOnRemoteValidation = () => jQuery("#rendered_signup_form :input[name='account[users_attributes][0][email]']").removeData("previousValue");

  return clearEmailVerificationFields = () => jQuery('#rendered_signup_form .email-validation').hide();
});

jQuery(document).on('click', 'a.referral_code_link', function(e) {
  e.preventDefault();
  return jQuery('p.referral_code').toggle();
});

jQuery(document).on('submit', '#sign_up_modal form', e => jQuery('#sign_up_modal form input[type=submit]').prop('disabled', true));
